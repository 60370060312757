import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import LinkItem from 'common/LinkItem';

import Arrow from '../../../../common/icons/arrow-right.svg';
import { PoliciesAndReportsItemProps } from './models';

import './PoliciesAndReportsItem.scss';

const PoliciesAndReportsItem: FC<PoliciesAndReportsItemProps> = ({
  hit,
  viewPageLinkText,
  pdfDownloadLinkText,
}) => (
  <div className="policies-and-reports__item-holder">
    <h2 className="policies-and-reports__item-title h5">{hit.title}</h2>
    {hit.summaryText ? <p className="policies-and-reports__item-text">{hit.summaryText}</p> : null}
    {hit.isHtmlPageAvailable || hit.pdfDownloadLink ? (
      <div className="policies-and-reports__item-links">
        {hit.isHtmlPageAvailable ? (
          <Link to={hit.url} className="policies-and-reports__item-link link link--pink">
            {viewPageLinkText}
            <Arrow aria-hidden="true" />
          </Link>
        ) : (
          <LinkItem
            link={hit.pdfDownloadLink}
            title={pdfDownloadLinkText}
            linkClass="policies-and-reports__item-link link link--pink"
            hasIcon
            showTitle
            isLink
          />
        )}
      </div>
    ) : null}
    {hit.moreLinks.length ? (
      <div
        className={classNames('policies-and-reports__item-cta-links', {
          'policies-and-reports__item-cta-links--margin':
            hit.isHtmlPageAvailable || hit.pdfDownloadLink,
        })}
      >
        {hit.moreLinks.map((link) => (
          <LinkItem
            link={link.ctaLink}
            title={link.title}
            linkClass="link link--black-grey policies-and-reports__item-cta-link"
            hasIcon
            key={link.ctaLink[0]?.url}
            isLink
            showTitle
          />
        ))}
      </div>
    ) : null}
  </div>
);

export default PoliciesAndReportsItem;
