import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';
import PolicyDetailListing from 'components/PolicyDetailListing';
import SimpleBanner from 'components/SimpleBanner';

import { PoliciesAndReportsPageTypes } from './models';

const PoliciesAndReportsPage: FC<PoliciesAndReportsPageTypes.PoliciesAndReportsPageType> = ({
  data: { policiesAndReportsPage },
}) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    policiesAndReportsPage.seo;
  const {
    pageId,
    level,
    lang,
    title,
    banner,
    mostViewedBoxes,
    numberOfResultsPerLoad,
    viewPageLinkText,
    pdfDownloadLinkText,
    helperFields,
    url,
  } = policiesAndReportsPage;

  return (
    <Layout>
      <Seo
        {...{
          seo: policiesAndReportsPage.seo,
          openGraph: policiesAndReportsPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="banners">
        <Breadcrumbs level={level} />
        <SimpleBanner {...banner} />
      </div>

      <PolicyDetailListing
        pageId={pageId}
        {...helperFields}
        mostViewedBoxes={mostViewedBoxes}
        lang={lang}
        numberOfResultsPerLoad={numberOfResultsPerLoad}
        viewPageLinkText={viewPageLinkText}
        pdfDownloadLinkText={pdfDownloadLinkText}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    policiesAndReportsPage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentSimpleBanner
      }
      mostViewedBoxes {
        ...FragmentMostViewedBoxes
      }
      numberOfResultsPerLoad
      viewPageLinkText
      pdfDownloadLinkText
      helperFields {
        ...FragmentPolicyDetailFiltersHelperFields
      }
    }
  }
`;

export default PoliciesAndReportsPage;
