import React, { FC, useState } from 'react';
import { Configure } from 'react-instantsearch-dom';

import { Container } from 'layout';
import CustomCurrentRefinements from 'common/CustomCurrentRefinements';
import Filters from 'common/Filters';
import CustomResultsHandler from 'common/Filters/CustomResultsHandler';
import InstantSearchContainer from 'common/InstantSearchContainer';

import CustomPoliciesAndReportsHits from './CustomPoliciesAndReportsHits';
import { PolicyDetailListingProps } from './models';
import { getNumberOfPoliciesPerLoad } from './utils';

import './PolicyDetailListing.scss';

const PolicyDetailListing: FC<PolicyDetailListingProps> = ({
  pageId,
  searchPlaceholder,
  searchText,
  selectedFiltersText,
  documentTypesDropdownText,
  categoriesDropdownText,
  numberOfResultsText,
  clearFiltersText,
  loadMoreButtonText,
  noResultsText,
  mostViewedBoxes,
  lang,
  numberOfResultsPerLoad,
  viewPageLinkText,
  pdfDownloadLinkText,
}) => {
  const [cleared, setCleared] = useState<boolean>(false);

  const resetClear = () => {
    setCleared(false);
  };

  const clear = () => {
    setCleared(true);
  };

  return (
    <InstantSearchContainer
      indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-policies`}
      cleared={cleared}
    >
      <Configure
        hitsPerPage={getNumberOfPoliciesPerLoad(numberOfResultsPerLoad)}
        numericFilters={[`landingPageId = ${pageId}`]}
        filters={`lang: "${lang}"`}
      />
      <Filters
        searchBtnText={searchText}
        selectedFiltersText={selectedFiltersText}
        searchPlaceholder={searchPlaceholder}
        firstFilterText={documentTypesDropdownText}
        secondFilterText={categoriesDropdownText}
        filters={['documentType', 'category.name']}
        numberOfResultsText={numberOfResultsText}
        clearFiltersText={clearFiltersText}
        mostViewedBoxes={mostViewedBoxes}
        clear={clear}
        resetClear={resetClear}
      />
      <Container>
        <CustomCurrentRefinements />
      </Container>
      <CustomResultsHandler noResultsText={noResultsText}>
        <CustomPoliciesAndReportsHits
          loadMoreText={loadMoreButtonText}
          viewPageLinkText={viewPageLinkText}
          pdfDownloadLinkText={pdfDownloadLinkText}
        />
      </CustomResultsHandler>
    </InstantSearchContainer>
  );
};

export default PolicyDetailListing;
